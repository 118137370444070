import ArrowUpThickIcon from "mdi-react/ArrowUpThickIcon";
import React from "react";
import './positive-icon.scss'

const PositiveIcon = () => {
    return (
        <ArrowUpThickIcon className={'positive-icon'} />
    )
}

export default PositiveIcon