import axios from 'axios';

export function fetchBalance(){

    const url = 'https://api.oddy.no/my-etheroll/balance';

    return axios.get(url)
        .then(response => {
            return transformEtherollDataFromWS(response)
        })
        .catch(error => {
            throw new Error(error)
        })

}

function transformEtherollDataFromWS({data}) {
    const {balance, base_bankroll, uniswap10x_buy_rate} = data
    return {
        balance,
        base_bankroll,
        bets: data['24h_bets'],
        players: data['24h_players'],
        eth_wagered: data['24h_eth_wagered'],
        house_profit: data['24h_house_profit'],
        uniswap10x_buy_rate: uniswap10x_buy_rate,
    };
}