import axios from 'axios';

export function fetchDaiSupply(){

    const url = 'https://api.makerburn.com/status';

    return axios.get(url)
        .then(response => {
            return transformMakerDataFromWS(response)
        })
        .catch(error => {
            throw new Error(error)
        })

}

function transformMakerDataFromWS({data}) {
    const {dai_total, sai_total, date, status_24h_ago, total_dai_ath} = data;
    return {
        total: dai_total,
        total24h: status_24h_ago.dai_total,
        sai_total: sai_total,
        total_dai_ath: total_dai_ath,
        date,
    };
}