import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Timer from "../util/Timer";
import {getRateEthUSD, loadRates} from "../../ducks/ratesReducer";
import Rates from "../rates/Rates";
import Title from "../util/Title";
import {loadBalance} from "../../ducks/etherollReducer";
import {loadDaiSupply} from "../../ducks/makerReducer";

const Dashboard = () => {
    const dispatch = useDispatch();
    const price = useSelector(state=>getRateEthUSD(state));
    return(
        <>
            <Timer
                offset={5}
                interval={5}
                instantly={true}
                action={()=>dispatch(loadRates())}
            />
            <Timer
                offset={10}
                instantly={true}
                action={()=>dispatch(loadBalance())}
            />
            <Timer
                offset={1}
                instantly={true}
                action={()=>dispatch(loadDaiSupply())}
            />
            <Title text={`$${price}`} />
            <Rates />
        </>
    );
}
;

export default Dashboard;