import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import './body.scss';
import dayjs from "dayjs";

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

const start = '2021-03-26 03:05:37'
const end = '2021-04-29 13:48:09'
const weight = 199.52189 / 1000.20469

const weightedDiff = dayjs(end).diff(dayjs(start)) * weight

console.log(dayjs(start).add(weightedDiff).toISOString())

console.log(800.68280000 + 199.52189)
console.log(4.94025900 + 0.938059)