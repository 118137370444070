import React from "react";

class Timer extends React.Component {

    constructor(props) {
        super(props);
        const {offset, instantly = true} = props;
        const timeOut = setTimeout(this.setTimer, offset * 1000);
        this.state = {
            timeOut: timeOut
        };
        if(instantly && offset > 0)this.makeAction();
    }

    componentWillUnmount() {
        const {timeOut} = this.state;
        clearTimeout(timeOut);
    }

    setTimer = () => {
        const {interval = 30} = this.props;
        const timeOut = setTimeout(this.setTimer, interval * 1000);
        this.setState({
            timeOut: timeOut
        });
        this.makeAction();
    };

    makeAction = () => {
        const {action} = this.props;
        action();
    };

    render() {
        return null;
    }

}

export default Timer;