import {fetchDaiSupply} from "../api/makerApi";

export const NAME = 'maker';

export const FETCH_DATA_PENDING = `${NAME}/FETCH_DATA_PENDING`;
export const FETCH_DATA_FAILURE = `${NAME}/FETCH_DATA_FAILURE`;
export const FETCH_DATA_SUCCESS = `${NAME}/FETCH_DATA_SUCCESS`;

export const loadDaiSupply = () => dispatch => {
    dispatch({ type: FETCH_DATA_PENDING });
    fetchDaiSupply().then(supply =>
        dispatch({
            type: FETCH_DATA_SUCCESS,
            supply,
        })
    ).catch(error =>
        dispatch({
            type: FETCH_DATA_FAILURE,
            error,
        })
    )
};

const initialState = {
    total: 0,
    total24h: 0,
    sai_total: 0,
    total_dai_ath: false,
    date: undefined,
    isLoading: false,
    error: undefined,
};


const reducer = (state = initialState, {type, error, supply}) => {
    switch(type){
        case FETCH_DATA_PENDING:
            return {
                ...state,
                isLoading: true,
            };
        case FETCH_DATA_SUCCESS:
            return {
                ...state,
                ...supply,
                isLoading: false,
            };
        case FETCH_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                error,
            };
        default:
            return state;
    }
};

const getState = state => state[NAME];

export const getDaiSupply = state => getState(state).total;
export const getDai24hSupply = state => getState(state).total24h;
export const getSaiSupply = state => getState(state).sai_total;
export const getIsDaiAth = state => getState(state).total_dai_ath;
export const getSaiSupplyInMillions = state => getSaiSupply(state)/(10**6);
export const getDaiSupplyInMillions = state => (getDaiSupply(state)/(10**6));
export const getDai24hSupplyInMillions = state => (getDai24hSupply(state)/(10**6));

export default reducer;