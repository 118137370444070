import React from 'react';
import {numberFormat} from "../../util/formatters/numbers";

const APPLICATION_NAME = 'Dashboard';

export default class Title extends React.Component {
    constructor(props){
        super(props);
        this.setPageTitle(props);
    }
    componentWillUpdate(nextProps) {
        this.setPageTitle(nextProps);
    }

    setPageTitle = ({text}) => {
        if (text){
            document.title = `${numberFormat(text,2)} - ${APPLICATION_NAME}`;
        }
    };
    render(){
        return null;
    }
}