import React from 'react';
import Counter from "../util/Counter";
import {useSelector} from "react-redux";
import PositiveIcon from "../icons/PositiveIcon";
import NegativeIcon from "../icons/NegativeIcon";


const Rate = ({
                  selector,
                  changeSelector,
                  decimals = 0,
                  header,
                  url = 'https://oddy.no',
                  isAth = false,
                  ...restOfProps
              }) => {
    const value = useSelector(selector)
    const changeValue = useSelector(changeSelector || selector)
    const diff = value - changeValue
    const diffPercent = diff / changeValue
    return (
        <div className={`rate ${isAth ? 'is-ath' : ''}`}>
            <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
            >
                {header &&
                <h5>
                    {header}
                </h5>
                }
                <Counter
                    decimals={decimals}
                    amount={value}
                    {...restOfProps}
                />
                {changeSelector &&
                    <div className='change'>
                        <Counter
                            prefix={diffPercent < 0 ? <NegativeIcon /> : <PositiveIcon />}
                            decimals={2}
                            amount={diffPercent * 100}
                            absolute={true}
                            postfix={'%'}
                        />
                    </div>
                }
            </a>
        </div>
    )
}

export default Rate;