import React from 'react';
import {Provider} from "react-redux";
import Dashboard from "./components/dashboard/Dashboard";
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from './ducks/configureStore';

const {store, persistor} = configureStore();

export default function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Dashboard />
            </PersistGate>
        </Provider>
    );
};
