import axios from 'axios';

const transformRatesFromWS = ({data}) => data;

export function fetchRates(){

    // const url = 'https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD,EUR,DICE,MKR';
    const url = 'https://api.oddy.no/dashboard/rates';
    // const url = 'http://localhost:6969/dashboard/rates';

    return axios.get(url)
        .then(response => {
            return transformRatesFromWS(response)
        })
        .catch(error => {
            throw new Error(error)
        })

}

function transformHistoryFromWS(response) {
    const data = response.data.Data;
    const open = data[0].open;
    const close = data[data.length-1].close;
    return {
        open, close
    };
}

export function fetchRateChange(){

    const url = 'https://min-api.cryptocompare.com/data/histohour?fsym=DICE&tsym=ETH&limit=24&aggregate=1';

    return axios.get(url)
        .then(response => {
            return transformHistoryFromWS(response)
        })
        .catch(error => {
            return error
        })

}