import {fetchRates} from "../api/ratesApi";

export const NAME = 'rates';

export const FETCH_DATA_PENDING = `${NAME}/FETCH_DATA_PENDING`;
export const FETCH_DATA_FAILURE = `${NAME}/FETCH_DATA_FAILURE`;
export const FETCH_DATA_SUCCESS = `${NAME}/FETCH_DATA_SUCCESS`;

export const loadRates = () => dispatch => {
    dispatch({ type: FETCH_DATA_PENDING });
    fetchRates().then(rates =>
        dispatch({
            type: FETCH_DATA_SUCCESS,
            rates,
        })
    ).catch(error =>
        dispatch({
            type: FETCH_DATA_FAILURE,
            error,
        })
    )
};

const initialState = {
    rates: {
        "USD": 256.22,
        "EUR": 228.83,
        "DICE": 407.17,
        "MKR": 0.3584,
        "IDLE": 13,
    },
    isLoading: false,
    error: undefined,
};

export const randomAdd = n => n + (Math.random() * 2) - 1;

const reducer = (state = initialState, {type, error, rates}) => {
    switch(type){
        case FETCH_DATA_PENDING:
            return {
                ...state,
                isLoading: true,
            };
        case FETCH_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                rates,
            };
        case FETCH_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                error,
            };
        default:
            return state;
    }
};

const getState = state => state[NAME];

const getRates = state => getState(state).rates;

export const getRateEthUSD = state => getRates(state).ETH_USD || 0;
export const getRateEthOpenUSD = state => getRates(state).ETH_USD_OPEN || 0;
export const getRateEthEUR = state => getRates(state).ETH_EUR || 0;
export const getRateEthAthUSD = state => getRates(state).ETH_USD_ATH || 0;

export const getRateBtcUSD = state => getRates(state).BTC_USD || 0;
export const getRateBtcOpenUSD = state => getRates(state).BTC_USD_OPEN || 0;
export const getRateBtcAthUSD = state => getRates(state).BTC_USD_ATH || 0;

export const getRateUniUSD = state => getRates(state).UNI_USD || 0;
export const getRateUniOpenUSD = state => getRates(state).UNI_USD_OPEN || 0;
export const getRateUniAthUSD = state => getRates(state).UNI_USD_ATH || 0;

export const getRateElk = state => getRates(state).ELK_NOK || 0;
export const getRateElkOpen = state => getRates(state).ELK_NOK_OPEN || 0;
export const getRateElkAth = state => getRates(state).ELK_NOK_ATH || 0;

export const getRateDICE = state => 1 / getRates(state).DICE;

export const getRateMkr = state => getRates(state).MKR_USD;
export const getRateMkrOpen = state => getRates(state).MKR_USD_OPEN;
export const getRateMkrAthUSD = state => getRates(state).MKR_USD_ATH || 0;

export const getRateIdleUSD = state => getRates(state).IDLE_USD || 0;
export const getRateIdleOpenUSD = state => getRates(state).IDLE_USD_OPEN || 0;
export const getRateIdleAthUSD = state => getRates(state).IDLE_USD_ATH || 0;

export const getPoolApyList = state => getRates(state).poolApyList || []
export const getPoolApy = (state, adr) => getPoolApyList(state).find(pool => pool.pool_adr === adr).apy

export const getRateUsd = state => getRates(state).USD_NOK;
export const getRateUsdYesterday = state => getRates(state).USD_NOK_Y;
export const getRateEuro = state => getRates(state).EUR_NOK;
export const getRateEuroYesterday = state => getRates(state).EUR_NOK_Y;

export default reducer;