import React from "react";
import ArrowDownThickIcon from "mdi-react/ArrowDownThickIcon";
import './negative-icon.scss'

const NegativeIcon = () => {
    return (
        <ArrowDownThickIcon className={'negative-icon'} />
    )
}

export default NegativeIcon