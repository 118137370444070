import {combineReducers} from "redux";

import ratesReducer, {NAME as rates} from './ratesReducer';
import etherollReducer, {NAME as etheroll} from './etherollReducer';
import makerReducer, {NAME as maker} from './makerReducer';

export default combineReducers({
    [rates]: ratesReducer,
    [etheroll]: etherollReducer,
    [maker]: makerReducer,
});