import React from 'react';
import {
    getRateBtcAthUSD,
    getRateBtcOpenUSD,
    getRateBtcUSD, getRateElk, getRateElkAth, getRateElkOpen,
    getRateEthAthUSD,
    getRateEthOpenUSD,
    getRateEthUSD, getRateEuro, getRateEuroYesterday,
    getRateIdleAthUSD,
    getRateIdleOpenUSD,
    getRateIdleUSD,
    getRateMkr,
    getRateMkrAthUSD,
    getRateMkrOpen,
    getRateUniAthUSD,
    getRateUniOpenUSD,
    getRateUniUSD, getRateUsd, getRateUsdYesterday
} from "../../ducks/ratesReducer";
import Rate from "./Rate";

import './rates.scss';
import {getDai24hSupplyInMillions, getDaiSupplyInMillions, getIsDaiAth} from "../../ducks/makerReducer";
import {useSelector} from "react-redux";

const Rates = () => {

    const ethAth = useSelector(getRateEthAthUSD)
    const ethRate = useSelector(getRateEthUSD)
    const ethIsAth = ethAth * .98 < ethRate

    const btcAth = useSelector(getRateBtcAthUSD)
    const btcRate = useSelector(getRateBtcUSD)
    const btcIsAth = btcAth * .98 < btcRate

    const idleAth = useSelector(getRateIdleAthUSD)
    const idleRate = useSelector(getRateIdleUSD)
    const idleIsAth = idleAth * .98 < idleRate

    const uniAth = useSelector(getRateUniAthUSD)
    const uniRate = useSelector(getRateUniUSD)
    const uniIsAth = uniAth * .98 < uniRate

    const elkAth = useSelector(getRateElkAth)
    const elkRate = useSelector(getRateElkOpen)
    const elkIsAth = elkAth * .98 < elkRate

    const mkrAth = useSelector(getRateMkrAthUSD)
    const mkrRate = useSelector(getRateMkr)
    const mkrIsAth = mkrAth * .98 < mkrRate

    const daiSupplyInMillions = useSelector(getDaiSupplyInMillions)
    const isDaiAth = useSelector(getIsDaiAth)

    const rateUsd = useSelector(getRateUsd)
    const rateUsdYesterday = useSelector(getRateUsdYesterday)
    const rateEuro = useSelector(getRateEuro)
    const rateEuroYesterday = useSelector(getRateEuroYesterday)

    const decimalThreshold = 9999

    return (
        <div className={'rates'}>
            <Rate
                header={'USD/ETH'}
                prefix={`$`}
                decimals={decimalThreshold > ethRate ? 2 : 0}
                selector={getRateEthUSD}
                changeSelector={getRateEthOpenUSD}
                url={`https://www.coingecko.com/en/coins/ethereum`}
                isAth={ethIsAth}
            />
            <Rate
                header={'BTC/USD'}
                prefix={`$`}
                decimals={0}
                selector={getRateBtcUSD}
                changeSelector={getRateBtcOpenUSD}
                url={`https://www.coingecko.com/en/coins/bitcoin`}
                isAth={btcIsAth}
            />
            <Rate
                header={'USD/NOK'}
                decimals={4}
                selector={getRateUsd}
                changeSelector={getRateUsdYesterday}
                url={`https://www.dn.no/investor/valuta/Y9/Amerikanske%20dollar`}
            />
            <Rate
                header={'ELK/NOK'}
                prefix={`kr `}
                decimals={2}
                selector={getRateElk}
                changeSelector={getRateElkOpen}
                url={`https://coinmarketcap.com/currencies/uni/`}
                isAth={elkIsAth}
            />
            <Rate
                header={'MKR/USD'}
                prefix={`$`}
                decimals={decimalThreshold > mkrRate ? 2 : 0}
                selector={getRateMkr}
                changeSelector={getRateMkrOpen}
                url={`https://www.coingecko.com/en/coins/maker`}
                isAth={mkrIsAth}
            />
            <Rate
                header={'DAI SUPPLY'}
                decimals={decimalThreshold > daiSupplyInMillions ? 1 : 0}
                postfix={'M'}
                selector={getDaiSupplyInMillions}
                changeSelector={getDai24hSupplyInMillions}
                url={`https://makerburn.com`}
                isAth={isDaiAth}
            />
        </div>
    );
};

export default Rates;
