import {fetchBalance} from "../api/etherollApi";

export const NAME = 'etheroll';

export const FETCH_DATA_PENDING = `${NAME}/FETCH_DATA_PENDING`;
export const FETCH_DATA_FAILURE = `${NAME}/FETCH_DATA_FAILURE`;
export const FETCH_DATA_SUCCESS = `${NAME}/FETCH_DATA_SUCCESS`;

export const loadBalance = () => dispatch => {
    dispatch({ type: FETCH_DATA_PENDING });
    fetchBalance().then(balance =>
        dispatch({
            type: FETCH_DATA_SUCCESS,
            balance,
        })
    ).catch(error =>
        dispatch({
            type: FETCH_DATA_FAILURE,
            error,
        })
    )
};

const initialState = {
    balance: 0,
    base_bankroll: 0,
    bets: 0,
    players: 0,
    eth_wagered: 0,
    house_profit: 0,
    uniswap10x_buy_rate: 0,
    isLoading: false,
    error: undefined,
};


const reducer = (state = initialState, {type, error, balance}) => {
    switch(type){
        case FETCH_DATA_PENDING:
            return {
                ...state,
                isLoading: true,
            };
        case FETCH_DATA_SUCCESS:
            return {
                ...state,
                ...balance,
                isLoading: false,
            };
        case FETCH_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
                error,
            };
        default:
            return state;
    }
};

const getState = state => state[NAME];

export const getEtherollProfits = state => getState(state).balance - getState(state).base_bankroll;
export const getEtherollProfitsChange = state => getState(state).house_profit;
export const getEtherollPlayers = state => getState(state).players;
export const getEtherollWagered = state => getState(state).eth_wagered;
export const getEtherollBets = state => getState(state).bets;
export const getUniswap10xBuyRate = state => getState(state).uniswap10x_buy_rate;

export default reducer;