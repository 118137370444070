import React, {Component} from 'react';
import {numberFormat} from "../../util/formatters/numbers";

import './counter.scss';

export default class Counter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            amount: props.amount || 0
            , transition: false
            , targetAmount: 0
            , decimals: props.decimals || 0
            , positivity: 0
            , defaultHeader: document.title
            , updateTitle: props.updateTitle || false
        };
        this.timeOut = 0;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.amount !== this.state.targetAmount) {
            if (this._ismounted) this.setState({
                targetAmount: nextProps.amount
                , decimals: nextProps.decimals || 0
            }, () => {
                this.count()
            })
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeOut);
        this._ismounted = false;
    };

    componentDidMount() {
        if (this.props.debug) {
            console.log(this.props.amount)
        }
        this._ismounted = true;
    }

    count = () => {
        const amount = this.state.amount;
        const targetAmount = this.state.targetAmount;
        let newAmount = 0;
        let diff = amount - targetAmount;
        let positivity = 0;
        if (diff < 0) {
            positivity = 1
        }
        if (diff > 0) {
            positivity = -1
        }
        if (amount !== targetAmount) {
            const limit = this.state.decimals === 0 ? .001 : .002 / (40 * this.state.decimals);
            if (Math.abs(diff) < limit || amount === 0) {
                if (this._ismounted) this.setState({
                    amount: targetAmount
                    , transition: false
                    , positivity: positivity
                })
            } else {
                newAmount = amount - diff * .2;
                if (this._ismounted) this.setState({
                    amount: newAmount
                    , transition: true
                    , positivity: positivity
                }, () => {
                    this.timeOut = setTimeout(this.count, 50)
                })
            }
        } else {
            clearTimeout(this.timeOut)
        }
    };

    updateHeader = amount => {
        if (!this.state.updateTitle) return null;
        document.title = Math.round(amount) + " • " + this.state.defaultHeader
    };

    render() {

        let {decimals} = this.props;
        const {smartDecimals, absolute, noColor, debug} = this.props;
        const {amount, positivity} = this.state;

        const classNames = ['counter'];
        classNames.push(this.state.transition ? 'changing' : 'stable');
        if (positivity > 0 && !noColor) {
            classNames.push('positive');
        }
        if (positivity < 0 && !noColor) {
            classNames.push('negative');
        }
        if (this.props.bold) {
            classNames.push('bold');
        }

        if (smartDecimals) {
            if (Math.abs(amount) > 250) {
                decimals = -1
            } else if (amount === 0 || Math.round(amount) === amount) {
                decimals = 0
            } else if (Math.abs(amount) < 0.0002) {
                decimals = 8
            } else if (Math.abs(amount) < 0.02) {
                decimals = 6
            } else if (Math.abs(amount) < 2) {
                decimals = 4
            } else {
                decimals = 2
            }
        }

        if (debug) {
            console.log(amount, decimals)
            console.log(typeof Math.round(amount))
            console.log(typeof amount)
            console.log(Math.round(amount) === amount)
        }

        this.updateHeader(amount);

        const delimiter = '.';
        const formattedAmount = "" + numberFormat((absolute ? Math.abs(amount) : amount), decimals);
        const formattedAmountParts = formattedAmount.split(delimiter);

        return (
            <div className={classNames.join(' ')}>
                <span className="counter-prefix">{this.props.prefix}</span>
                <span className="counter-amount">
                  <span className="digits">
                    {formattedAmountParts[0]}
                  </span>
                  {formattedAmountParts[1] &&
                  <span className="decimals">
                    {delimiter}{formattedAmountParts[1]}
                  </span>
                  }
                </span>
                <span className="counter-postfix">
                    {this.props.postfix}
                </span>
            </div>
        )
    }
}